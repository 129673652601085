.active_sidebar_nav{
    background-color: #fff !important;
    border-left: 4px solid #0277fa !important;
}

.active_nav_icon{
    fill: #0277fa !important
}
 .active_nav_text{
    color: #0277fa !important
}
.nav_icon{
    fill: #3a3b3c !important
}
 .nav_text{
    color: #3a3b3c !important;
}
.nav_text > span{
    font-size: 40px !important;
}
.sidebar_nav{
    background-color: #fff !important;
    border-left: 4px solid #fff !important;
}


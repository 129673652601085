.parentDiv {
    width: 150px;
    height: 150px;
    z-index: 160;
    margin: 40vh auto 40vh auto;
    border-radius: 100px;
}

.topmostDiv {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 300;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}



.loader {
    position: relative;
    width: 70px;
    height: 70px;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top: 4px solid #0277fa;
    animation: rotate 1.5s linear infinite;
    left: 29%;
    top: 29%;


}

.loader::before,
.loader::after {
    position: absolute;
    content: "";
    border-radius: 50%;
    box-sizing: border-box;
    border: 4px solid transparent;
}

.loader::after {
    inset: 0;
    border-bottom: 4px solid #0277fa;
    animation: rotate 0.5s linear infinite reverse;
}

.loader::before {
    inset: 4px;
    border-left: 4px solid #0277fa;
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
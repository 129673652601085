* {
    font-family: 'Poppins', sans-serif !important;
    box-sizing: border-box;
}

body {
    background: #fcfbfc !important;
    margin: 0
}

body a {
    text-decoration: none !important;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
    height: 7px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;

}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #c1cbd6;
}




.driverApplicationMainDiv .MuiTabs-scroller {
    overflow-x: auto !important;
}


/* .css-17lap18-MuiGrid-root{
    margin: 0 10px !important;
} */


.css-10hburv-MuiTypography-root {
    font-weight: 500 !important;



}

@media all and (max-width: 900px) {
    ::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
        height: 0px;
    }

    .MuiTabs-scroller {
        font-size: 0.5rem !important;
    }

}

@media all and (max-width: 600px) {

    /* .css-1lhmacw {
     width: 50%;
    } */
    .MuiTabs-scroller {
        width: 300px !important;
    }

}